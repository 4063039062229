"use client"

import { useMediaQuery } from "@react-hooks-library/core";
import TrackingPixel from "src/components/ads/TrackingPixel";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from "src/lib/styles/css";

//#region [Props]
type BgImagePageWrapperProps = {
    showSponsorBg?: boolean;
    adTargetId?: string;
    children: React.ReactNode;
    excludeSectionSpacing?: boolean;
    className?: string;
    useArticleBg?: boolean;
    mobileOnly?: boolean;
};
//#endregion

const clickDestination = "https://www.coca-cola.com/no/no/offerings/fanta-appelsin?utm_source=Filmweb&utm_medium=Sponsorship&utm_campaign=Filmweb_Fanta_Sponsorship";

//#region [Component]
export default function BgImagePageWrapper({ children, showSponsorBg = false, adTargetId, excludeSectionSpacing = false, className, useArticleBg = false, mobileOnly = false }: BgImagePageWrapperProps) {
    const isMobile = useMediaQuery(theme.mq.mobile)
    if (!showSponsorBg || (!isMobile && mobileOnly)) {
        return children;
    }
    return <SBgImageContainer className={className} $useArticleBg={useArticleBg}>
        <STopAdArea className="bgClickable" href={clickDestination} target="_blank" $excludeSectionSpacing={!!excludeSectionSpacing}>
            {adTargetId && <TrackingPixel targetId={adTargetId} />}
        </STopAdArea>
        {children}
    </SBgImageContainer>;
}
//#endregion

//#region [Styles]
const SBgImageContainer = styled.div<{$useArticleBg: boolean}>`
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(${p => p.$useArticleBg ? "/fantasponsorat2024/FantaMobilKinoprogrammetDefault750x350.png":"/fantasponsorat2024/Fanta-750x350-toppbanner.jpg"});
    background-size: contain;

    @media ${theme.mq.desktop} {

        background-image: url(${p => p.$useArticleBg ? "/fantasponsorat2024/Fanta_Fenix_1920x1200_2_solid_background_1.webp":"/fantasponsorat2024/Fanta_Fenix_1920x1200_webp.webp"});
    }

    @media screen and (min-width: ${theme.maxContentWidth}px) {
        background-size: auto;
    }
`;

const areaHeightDesktop = 270 - 35; // 35 is the margin above the section itself. (The gap between the menu and content)

const STopAdArea = styled.a<{ $excludeSectionSpacing: boolean }>`
    display: block;
    margin: 0 auto;
    max-width: ${theme.maxContentWidth}px;
    margin-bottom: -10px;

    aspect-ratio: 750 / 350;

    @media ${theme.mq.desktop} {
        aspect-ratio: ${theme.maxContentWidth} / ${p => p.$excludeSectionSpacing ? (areaHeightDesktop + 35) : (areaHeightDesktop)};
        margin-bottom: 0;
    }

`;
//#endregion