
import withWatchlist from 'src/components/filmwebid/withWatchlist';
import CSSPoster from 'src/components/posterrow/CSSPoster';
import Poster from 'src/components/posterrow/Poster';
import theme from 'src/components/themes/DefaultTheme';
import { MovieType, StreamingContentType } from 'src/lib/movieinfo/movieinfotypes';
import styled from 'src/lib/styles/css';
import { IFilmomtale } from 'src/lib/types/Filmomtale';
import React from "react";


//#region [Props]
type PosterRowProps = {
	posterList?: null | undefined | IFilmomtale[] | MovieType[] | StreamingContentType[];
	max?: number;
	renderPlaceholders?: boolean;
	className?: string;
};
//#endregion


//#region [Component]
export default function PosterRow({ posterList, max = 0, renderPlaceholders = false, className }: PosterRowProps) {
	if ((posterList?.length ?? 0) === 0) {
		return null;
	}

	const list = posterList!.slice(0, max > 0 ? max : posterList?.length!);
	return <SRowContainer className={className}>
		{list?.map(film => <WLPoster key={(film as any)._id ?? (film as any).mainVersionId ?? (film as any).id} film={film} sizes='(orientation: landscape) 203px, 25vw' wlcClassName="ItemContainer" />)}
		{renderPlaceholders && (list?.length ?? 0) < max && max > 0 && times(max - (list?.length ?? 0), (idx) => <Placeholder key={idx} className="ItemContainer" />)}
	</SRowContainer>;
}
//#endregion

//#region [Component]
type PlaceholderProps = {
	className?: string;
}

function Placeholder({ className }: PlaceholderProps) {
	return <div className={className}></div>
}
//#endregion

//#region [Styles]
const SRowContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	overflow-x: auto;
	gap: 10px;

	@media ${theme.mq.desktop} {
		//@media  (orientation: landscape) {
		flex-wrap: wrap;
		justify-content: space-between;
		gap: unset;
	}

	.ItemContainer {
		${CSSPoster}
		flex: 0 0 ${100 / 3.7}%;
		min-width: 0; // needed to prevent blowout
		@media ${theme.mq.desktop} {
			flex: 0 0 calc(${100 / 6}% - 5 * 10px);
		}
	}
`;

const SPoster = styled(Poster)`
	width: 100%;
`;
const WLPoster = withWatchlist(SPoster, "film");

//#endregion

function times(num: number, renderFunc: (index: number) => React.ReactNode): React.ReactNode | null {
	if (num > 0) {
		return Array.from(Array(num)).map((itm, index) => renderFunc(index));
	}
	return null;
}